<template>
  <a-form
    ref="formRef"
    class="myform"
    :model="formState"
    :rules="rules"
    :wrapper-col="{ span: 14 }"
    :scroll-to-first-error="true"
  >
    <a-row class="form-row mb-0">
      <a-col :sm="24">
        <a-form-item
          label="Kode"
          label-align="left"
          :label-col="{ sm: { span: 4 } }"
          :wrapper-col="{ sm: { span: 24 - 4 } }"
          required
          has-feedback
          name="code"
          help="Jika diisi 0 maka dilakukan auto generate number"
        >
          <a-input
            placeholder="Kode"
            width="100%"
            v-model:value="formState.code"
            :disabled="!isNew"
            :type="codeType"
            required
          />
        </a-form-item>
      </a-col>
    </a-row>
    <!-- REFERENSI -->
    <a-form-item
      class="mb-0 lh-1"
      :label-col="{ sm: { span: 5 } }"
      :wrapper-col="{ sm: { span: 24 - 5 } }"
    >
      <a-col :sm="{ span: 24, offset: 5 }">
        <a-row class="myform-vertical" layout="vertical" v-if="codes">
          <template v-for="(item, index) in codes.filter(i => !i.is_deleted)" :key="index">
            <a-input
              size="small"
              type="hidden"
              v-model:value="item.vendor_id"
              :disabled="formState.readOnly || item.id !== null"
            />
            <a-row class="form-row">
              <a-col :sm="5">
                <a-form-item
                  label="Kode Referensi"
                  label-align="left"
                  :name="['codes', index, 'code']"
                  :rules="{
                    required: true,
                    message: 'code tidak boleh kosong!',
                  }"
                  :help="
                    Array.isArray(item.errors)
                      ? item.errors.find(i => i.field === 'code')
                        ? item.errors.find(i => i.field === 'code').message
                        : undefined
                      : undefined
                  "
                  :validate-status="
                    Array.isArray(item.errors)
                      ? item.errors.find(i => i.field === 'code') !== null
                        ? 'error'
                        : undefined
                      : undefined
                  "
                >
                  <a-input
                    size="small"
                    type="number"
                    stylea="width: 150px"
                    v-model:value="item.code"
                    :disabled="
                      formState.readOnly ||
                        item.is_deleted ||
                        isEndDateExpired(item.end_date_temp) ||
                        item.audit_trail_note
                    "
                  />
                </a-form-item>
              </a-col>
              <a-col :sm="5">
                <a-form-item label="Tanggal Mulai" label-align="left">
                  <a-date-picker
                    size="small"
                    type="text"
                    stylea="width: 150px;"
                    :disabled-date="c => disabledStartDated(c, item.end_date)"
                    v-model:value="item.begin_date"
                    :disabled="
                      formState.readOnly ||
                        item.is_deleted ||
                        isEndDateExpired(item.end_date_temp) ||
                        item.audit_trail_note
                    "
                  />
                </a-form-item>
              </a-col>
              <a-col :sm="5">
                <a-form-item label="Tanggal Selesai" label-align="left">
                  <a-date-picker
                    size="small"
                    type="text"
                    stylea="width: 150px"
                    :disabled-date="c => disabledEndDated(item.begin_date, c)"
                    v-model:value="item.end_date"
                    :disabled="
                      formState.readOnly ||
                        item.is_deleted ||
                        isEndDateExpired(item.end_date_temp) ||
                        item.audit_trail_note
                    "
                  />
                </a-form-item>
              </a-col>
              <a-col :sm="5">
                <a-form-item
                  label="Brand"
                  label-align="left"
                  :name="['codes', index, 'brand_id']"
                  :rules="{
                    required: true,
                    message: 'brand tidak boleh kosong!',
                  }"
                  required
                  :help="
                    Array.isArray(item.errors)
                      ? item.errors.find(i => i.field === 'brand_id')
                        ? item.errors.find(i => i.field === 'brand_id').message
                        : undefined
                      : undefined
                  "
                  :validate-status="
                    Array.isArray(item.errors)
                      ? item.errors.find(i => i.field === 'brand_id') !== null
                        ? 'error'
                        : undefined
                      : undefined
                  "
                >
                  <filter-brand
                    size="small"
                    :mode="null"
                    type="text"
                    style="width: 100%"
                    v-model:value="item.brand_id"
                    :disabled="
                      formState.readOnly ||
                        item.is_deleted ||
                        isEndDateExpired(item.end_date_temp) ||
                        item.audit_trail_note
                    "
                  ></filter-brand>
                </a-form-item>
              </a-col>

              <a-col :sm="4">
                <a-form-item label=" " label-align="left">
                  <a-button
                    @click="removeRow(item)"
                    size="small"
                    type="danger"
                    style="margin-top: 22px"
                    :disabled="formState.readOnly"
                    ><i class="fa fa-remove"></i
                  ></a-button>
                </a-form-item>
              </a-col>
            </a-row>
          </template>
        </a-row>
        <!-- add function -->
        <template v-if="!formState.readOnly">
          <a-form-item
            class="mb-0"
            label-align="left"
            :wrapper-col="{ sm: { span: 24 - 4 - 8 } }"
            :colon="false"
          >
            <a-button type="primary" @click="addCode"
              ><i class="fa fa-plus"></i> Tambah Kode Referensi</a-button
            >
          </a-form-item>
        </template>
      </a-col>
    </a-form-item>

    <a-row class="form-row">
      <a-col :md="12" :sm="24">
        <a-form-item
          class="mb-0"
          label="Tanggal Registrasi"
          label-align="left"
          :label-col="{ sm: { span: 8 } }"
          :wrapper-col="{ sm: { span: 24 - 8 } }"
        >
          <a-date-picker
            placeholder="Tanggal Registrasi"
            v-model:value="formState.registration_at"
            value-format="YYYY-MM-DD"
            :disabled="formState.readOnly"
            style="width:100%;"/>
        </a-form-item>
      </a-col>
      <a-col :md="12" :sm="24">
        <AFormItem
          class="mb-0"
          label="Tanggal Lahir Pemilik"
          label-align="left"
          :label-col="{ sm: { span: 8 } }"
          :wrapper-col="{ sm: { span: 24 - 8 } }">
          <ADatePicker
            placeholder="Tanggal Lahir Pemilik"
            v-model:value="formState.owner_birthday_date"
            value-format="YYYY-MM-DD"
            :disabled="formState.readOnly"
            style="width:100%;"/>
        </AFormItem>
      </a-col>
    </a-row>

    <a-row class="form-row">
      <a-col :md="12" :sm="24">
        <a-form-item
          class="mb-0"
          label="Nama Toko"
          label-align="left"
          :label-col="{ sm: { span: 8 } }"
          :wrapper-col="{ sm: { span: 24 - 8 } }"
          required
          has-feedback
          name="name"
        >
          <a-input
            placeholder="Nama Toko"
            v-model:value="formState.name"
            :disabled="formState.readOnly"
            required
          />
        </a-form-item>
      </a-col>
      <a-col :md="12" :sm="24">
        <a-form-item
          class="mb-0"
          label="Nama Pemilik"
          label-align="left"
          :label-col="{ sm: { span: 8 } }"
          :wrapper-col="{ sm: { span: 24 - 8 } }"
          required
          has-feedback
          name="owner"
        >
          <a-input
            placeholder="Nama Pemilik"
            v-model:value="formState.owner"
            :disabled="formState.readOnly"
          />
        </a-form-item>
      </a-col>
    </a-row>

    <a-row class="form-row">
      <a-col :md="12" :sm="24">
        <a-form-item
          label="NPWP"
          class="mb-0"
          label-align="left"
          :label-col="{ sm: { span: 8 } }"
          :wrapper-col="{ sm: { span: 24 - 8 } }"
          required
          name="npwp"
        >
          <a-input
            placeholder="NPWP"
            v-model:value="formState.npwp"
            :disabled="formState.readOnly"
          />
        </a-form-item>
      </a-col>
      <a-col :md="12" :sm="24">
        <a-form-item
          label="Email"
          class="mb-0"
          label-align="left"
          :label-col="{ sm: { span: 8 } }"
          :wrapper-col="{ sm: { span: 24 - 8 } }"
          required
          name="email"
        >
          <a-input
            placeholder="Contoh : example@mail.com"
            v-model:value="formState.email"
            :disabled="formState.readOnly"
          >
          </a-input>
        </a-form-item>
      </a-col>
    </a-row>

    <a-row class="form-row">
      <a-col :md="12" :sm="24">
        <a-form-item
          label="Nomor Telepon"
          class="mb-0"
          label-align="left"
          :label-col="{ sm: { span: 8 } }"
          :wrapper-col="{ sm: { span: 24 - 8 } }"
          required
          name="phone"
        >
          <a-input
            addon-before="+62"
            placeholder="Ex : 81xxxxxxxxx"
            v-model:value="formState.phone"
            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
            :disabled="formState.readOnly"
            pattern="^[1-9][0-9]*$"
            required
            oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';"
            _type="text"
            maxlength="13"
          />
        </a-form-item>
      </a-col>
      <a-col :md="12" :sm="24">
        <a-form-item
          class="mb-0"
          label="Nomor Telepon 2"
          label-align="left"
          :label-col="{ sm: { span: 8 } }"
          :wrapper-col="{ sm: { span: 24 - 8 } }"
        >
          <a-input
            addon-before="+62"
            v-model:value="formState.phone2"
            placeholder="Ex : 81xxxxxxxxx"
            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
            :disabled="formState.readOnly"
            pattern="^[1-9][0-9]*$"
            required
            oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';"
            _type="text"
            maxlength="13"
          />
        </a-form-item>
      </a-col>
    </a-row>

    <a-row class="form-row">
      <a-col :md="12" :sm="24">
        <a-form-item
          label="Segment"
          class="mb-0"
          label-align="left"
          :label-col="{ sm: { span: 8 } }"
          :wrapper-col="{ sm: { span: 24 - 8 } }"
          required
        >
          <filter-segment
            v-model:value="formState.type"
            :disabled="formState.readOnly"
            :mode="null"
            show-search
            @change="selectSegmentValue"
          ></filter-segment>
        </a-form-item>
      </a-col>
      <a-col :md="12" :sm="24">
        <a-form-item
          label="Target Volume"
          label-align="left"
          class="mb-0"
          :label-col="{ sm: { span: 8 } }"
          :wrapper-col="{ sm: { span: 24 - 8 } }"
        >
          <a-input
            v-model:value="formState.target_volume"
            addon-after="TON"
            width="100%"
            type="number"
            :min="0"
            placeholder="Target Distributor"
            :disabled="formState.readOnly"
          />
        </a-form-item>
      </a-col>
    </a-row>

    <a-row v-show="isCustomerCurah" class="form-row">
      <a-col :md="12" :sm="24">
        <a-form-item
          label="Grup Pelanggan"
          class="mb-0"
          label-align="left"
          :label-col="{ sm: { span: 8 } }"
          :wrapper-col="{ sm: { span: 24 - 8 } }"
        >
          <suggest-customer-group
            v-model:value="formState.group_customer"
            :disabled="formState.readOnly"
            show-search
          ></suggest-customer-group>
        </a-form-item>
      </a-col>
    </a-row>

    <a-row class="form-row">
      <a-col :md="12" :sm="24">
        <a-form-item
          label="Foto KTP"
          label-align="left"
          class="mb-0"
          :label-col="{ sm: { span: 8 } }"
          :wrapper-col="{ sm: { span: 24 - 8 } }"
        >
          <template v-if="!formState.readOnly">
            <a-input
              required
              v-if="!foto_ktp || foto_ktp === 'null'"
              type="file"
              accept="image/png, image/gif, image/jpeg"
              @change="e => onFileChange(e, 'ktp')"
            ></a-input>
            <a-button
              v-else
              @click="
                () => {
                  foto_ktp = null
                  foto_ktp_image = null
                }
              "
              >Hapus ktp</a-button
            >
          </template>
          <img
            v-if="foto_ktp !== 'null'"
            style="display: block; max-width:150px;
                  width: auto;
                  height: auto;"
            :src="typeof foto_ktp === 'string' ? foto_ktp : foto_ktp_image"
          />
        </a-form-item>
      </a-col>
      <a-col :md="12" :sm="24">
        <a-form-item
          label="Foto Toko"
          class="mb-0"
          label-align="left"
          :label-col="{ sm: { span: 8 } }"
          :wrapper-col="{ sm: { span: 24 - 8 } }"
        >
          <template v-if="!formState.readOnly">
            <a-input
              required
              v-if="!foto || foto === 'null'"
              type="file"
              accept="image/png, image/gif, image/jpeg"
              @change="e => onFileChange(e, 'foto')"
            ></a-input>
            <a-button
              v-else
              @click="
                () => {
                  foto = null
                  foto_image = null
                }
              "
              >Hapus foto</a-button
            >
          </template>
          <img
            v-if="foto !== 'null'"
            style="display: block; max-width:150px;
                  width: auto;
                  height: auto;"
            :src="typeof foto === 'string' ? foto : foto_image"
          />
        </a-form-item>
      </a-col>
    </a-row>

    <a-row class="form-row">
      <a-col :md="12" :sm="24">
        <a-form-item
          label="Logo Toko"
          label-align="left"
          class="mb-0"
          :label-col="{ sm: { span: 8 } }"
          :wrapper-col="{ sm: { span: 24 - 8 } }"
        >
          <template v-if="!formState.readOnly">
            <a-input
              required
              v-if="!logo || logo === 'null'"
              type="file"
              accept="image/png, image/gif, image/jpeg"
              @change="e => onFileChange(e, 'logo')"
            ></a-input>
            <a-button
              v-else
              @click="
                () => {
                  logo = null
                  logo_image = null
                }
              "
              >Hapus foto</a-button
            >
          </template>
          <img
            v-if="logo !== 'null'"
            style="display: block; max-width:150px;
                  width: auto;
                  height: auto;"
            :src="typeof logo === 'string' ? logo : logo_image"
          />
        </a-form-item>
      </a-col>
      <a-col :md="12" :sm="24">
        <a-form-item
          label="Dokumen Toko"
          label-align="left"
          class="mb-0"
          :label-col="{ sm: { span: 8 } }"
          :wrapper-col="{ sm: { span: 24 - 8 } }"
        >
          <template v-if="!formState.readOnly">
            <a-input
              required
              v-if="!document || document === 'null'"
              type="file"
              @change="e => onFileChange(e, 'document')"
            ></a-input>
            <a-button
              v-else
              @click="
                () => {
                  document = null
                }
              "
              >Hapus Dokumen</a-button
            ><br />
          </template>
          <a
            v-if="
              document !== null &&
                document !== undefined &&
                typeof document === 'string' &&
                document !== 'null'
            "
            :href="!document ? 'javascript.void(0)' : document"
            target="_blank"
            >Lihat Dokumen</a
          >
        </a-form-item>
      </a-col>
    </a-row>

    <a-row class="form-row">
      <a-col :sm="24">
        <a-form-item
          label="Alamat"
          label-align="left"
          class="mb-0"
          :label-col="{ sm: { span: 4 } }"
          :wrapper-col="{ sm: { span: 24 - 4 } }"
          required
          name="address"
        >
          <a-input
            placeholder="Alamat"
            v-model:value="formState.address"
            :disabled="formState.readOnly"
          />
        </a-form-item>
      </a-col>
    </a-row>

    <a-row class="form-row">
      <a-col :sm="24">
        <a-form-item
          label="Kabupaten/Kota"
          class="mb-0"
          label-align="left"
          :label-col="{ sm: { span: 4 } }"
          :wrapper-col="{ sm: { span: 24 - 4 } }"
          required
          name="wilayah_id"
        >
          <filter-wilayah-semua
            v-model:value="formState.wilayah_id"
            label="fullname"
            :disabled="formState.readOnly"
            :join-options="
              formState.wilayah_id
                ? [{ id: formState.wilayah_id, fullname: formState.wilayah }]
                : []
            "
          ></filter-wilayah-semua>
        </a-form-item>
      </a-col>
    </a-row>

    <a-row class="form-row">
      <a-col :sm="24">
        <a-form-item
          label="Keterangan"
          label-align="left"
          class="mb-0"
          :label-col="{ sm: { span: 4 } }"
          :wrapper-col="{ sm: { span: 24 - 4 } }"
        >
          <a-input
            placeholder="Keterangan"
            width="100%"
            v-model:value="formState.note"
            :disabled="formState.readOnly"
          />
        </a-form-item>
      </a-col>
    </a-row>

    <a-row class="form-row">
      <a-col :sm="24">
        <a-form-item
          label="Pilih Distributor"
          class="mb-0"
          label-align="left"
          :label-col="{ sm: { span: 4 } }"
          :wrapper-col="{ sm: { span: 24 - 4 } }"
          :max-tag-count="3"
          required
          name="vendors"
          allow-clear
        >
          <select-distributor
            show-search
            v-model:value="formState.vendors"
            :join-options="formState.vendorRaw ? formState.vendorRaw : []"
            label="fullname"
            style="width: 100%"
            :disabled="formState.readOnly"
            v-model:items="distributors"
          ></select-distributor>
        </a-form-item>
      </a-col>
    </a-row>

    <a-row class="form-row">
      <a-col :md="12" :sm="24">
        <a-form-item
          label="Latitude"
          label-align="left"
          class="mb-0"
          :label-col="{ sm: { span: 8 } }"
          :wrapper-col="{ sm: { span: 24 - 8 } }"
        >
          <a-input
            placeholder="e.g 00.0000"
            v-model:value="formState.lat"
            :disabled="formState.readOnly"
            :class="!formState.lat ? '' : isValidLatitude != true ? `danger-input` : ``"
            show-count
            :maxlength="18"
            type="text"
          />
          <span
            v-if="!formState.lat ? '' : isValidLatitude != true"
            :class="
              !formState.lat ? '' : isValidLatitude != true ? `text-danger position-danger` : ``
            "
            >*Format anda salah</span
          >
        </a-form-item>
      </a-col>
      <a-col :md="12" :sm="24">
        <a-form-item
          label="Longitude"
          class="mb-0"
          label-align="left"
          :label-col="{ sm: { span: 8 } }"
          :wrapper-col="{ sm: { span: 24 - 8 } }"
        >
          <a-input
            placeholder="e.g 00.0000"
            v-model:value="formState.lng"
            :disabled="formState.readOnly"
            type="text"
            :class="!formState.lng ? '' : isValidLongitude != true ? `danger-input` : ``"
            show-count
            :maxlength="18"
          />
          <span
            v-if="!formState.lng ? '' : isValidLongitude != true"
            :class="
              !formState.lng ? '' : isValidLongitude != true ? `text-danger position-danger` : ``
            "
            >*Format anda salah</span
          >
        </a-form-item>
      </a-col>
    </a-row>

    <a-row class="form-row">
      <a-col :md="12" :sm="24">
        <a-form-item
          class="mb-0"
          label="Pilih Cluster"
          label-align="left"
          :label-col="{ sm: { span: 8 } }"
          :wrapper-col="{ sm: { span: 24 - 8 } }"
        >
          <filter-cluster
            v-model:value="formState.cluster_id"
            :disabled="formState.readOnly"
            style="width: 100%"
            :mode="null"
          ></filter-cluster>
        </a-form-item>
      </a-col>
      <a-col :md="12" :sm="24">
        <AFormItem
          class="mb-0"
          label="Kode Distrik SAP"
          label-align="left"
          :label-col="{ sm: { span: 8 } }"
          :wrapper-col="{ sm: { span: 24 - 8 } }">
          <a-input
            placeholder="kode distrik SAP"
            width="100%"
            v-model:value="formState.sap_code"
            :disabled="formState.readOnly"
            required
          />
        </AFormItem>
      </a-col>
    </a-row>

    <ARow class="form-row mb-2">
      <ACol :sm="24">
        <AFormItem
          label="Pilih Salesman"
          label-align="left"
          :label-col="{ sm: { span: 4 } }"
          :wrapper-col="{ sm: { span: 24 - 4 } }">
          <ARow
            v-if="distributors.length"
            :gutter="[8, 8]"
            class="myform-vertical"
            layout="vertical">
            <ACol
              v-for="(item, index) in distributors"
              :key="index"
              :span="12">
              <AFormItem
                :label="item.name"
                class="mb-0"
                label-align="left"
                :wrapper-col="{ span: 24 }"
                :name="['salesman', index, 'id']">
                <FilterSalesman
                  :key="index"
                  v-model:value="formState.salesman[item.id]"
                  v-model:items="formState.salesmans[index]"
                  :distributor_id="item.id"
                  :disabled="formState.readOnly"
                  style="width: 100%"
                  :mode="null"/>
              </AFormItem>
            </ACol>
          </ARow>
          <p v-else>Salesman akan tampil setelah pilih distributor.</p>
        </AFormItem>
      </ACol>
    </ARow>

    <a-row class="form-row">
      <a-col :sm="24">
        <a-form-item
          label="Sales Person"
          class="mb-0"
          label-align="left"
          :label-col="{ sm: { span: 4 } }"
          :wrapper-col="{ sm: { span: 24 - 4 } }"
          name="user"
        >
          <a-form-item
            class="mb-0"
            :label-col="{ sm: { span: 6 } }"
            :wrapper-col="{ sm: { span: 24 - 6 } }"
          >
            <a-col :sm="{ span: 24, offset: 6 }" :md="{ span: 12, offset: 0 }">
              <a-row class="myform-vertical" layout="vertical" v-if="users">
                <template v-for="(item, index) in users.filter(i => !i.is_deleted)" :key="index">
                  <a-input
                    size="small"
                    type="hidden"
                    v-model:value="item.vendor_id"
                    :disabled="formState.readOnly || item.id !== null"
                  />
                  <a-row class="form-row">
                    <a-col :sm="12" :md="12">
                      <a-form-item
                        label="Role"
                        class="mb-0"
                        label-align="left"
                        :wrapper-col="{ span: 24 }"
                        :name="['users', index, 'role_id']"
                      >
                        <filter-role
                          size="small"
                          :mode="null"
                          type="text"
                          class="mb-0 lh-1"
                          style="width: 95%"
                          v-model:value="item.role_id"
                          placeholder="Pilih Role"
                          :disabled="formState.readOnly || item.is_deleted || item.role_id !== null"
                        ></filter-role>
                      </a-form-item>
                    </a-col>
                    <a-col :sm="12" :md="12">
                      <a-form-item
                        label="User"
                        class="mb-0 "
                        label-align="left"
                        :wrapper-col="{ span: 26 }"
                        :name="['users', index, 'user_id']"
                        :help="
                          Array.isArray(item.errors)
                            ? item.errors.find(i => i.field === 'user_id')
                              ? item.errors.find(i => i.field === 'user_id').message
                              : undefined
                            : undefined
                        "
                        :validate-status="
                          Array.isArray(item.errors)
                            ? item.errors.find(i => i.field === 'user_id') !== null
                              ? 'error'
                              : undefined
                            : undefined
                        "
                      >
                        <filter-sales
                          size="small"
                          :mode="null"
                          type="text"
                          class="mb-0 lh-1"
                          v-model:value="item.user_id"
                          v-model:role_id="item.role_id"
                          v-model:wilayah_id="formState.wilayah_id"
                          :disabled="formState.readOnly || item.is_deleted"
                          @change="e => onChangeParent(e, index)"
                        ></filter-sales>
                      </a-form-item>
                    </a-col>
                  </a-row>
                </template>
              </a-row>
            </a-col>
          </a-form-item>
        </a-form-item>
      </a-col>
    </a-row>

    <a-row class="form-row" v-if="formState.reactive">
      <a-col :sm="24">
        <a-form-item
          label="Alasan diaktifkan"
          class="mb-0"
          label-align="left"
          :label-col="{ sm: { span: 4 } }"
          :wrapper-col="{ sm: { span: 24 - 4 } }"
        >
          <a-input
            placeholder=""
            width="100%"
            v-model:value="formState.audit_trail_note"
            :disabled="formState.readOnly"
          />
        </a-form-item>
      </a-col>
    </a-row>

    <!-- <pre>{{ formState }}</pre> -->
    <a-modal
      v-if="itemDelete.showReason"
      v-model:visible="itemDelete.showReason"
      title="Reason"
      @ok="submitReason"
    >
      <p>Alasan menghapus kode ini?</p>
      <a-input v-if="itemDelete.item" v-model:value="itemDelete.reason"></a-input>
      <a-alert v-if="itemDelete.error" :message="itemDelete.error" type="error" />
    </a-modal>
  </a-form>
</template>
<script>
import {
  defineComponent,
  ref,
  reactive,
  inject,
  computed,
  nextTick,
  toRef,
  onBeforeMount,
  toRaw,
  toRefs,
  watch,
} from 'vue'
import apiClient from '@/services/axios'
// import FilterWilayahSemua from '@/components/filter/FilterWilayahSemua'
import FilterProvince from '@/components/filter/FilterProvince'
import FilterBrand from '@/components/filter/FilterBrand'
import SelectDistributor from '@/components/select/SelectDistributor'
import { message } from 'ant-design-vue'
import rules from './rules'
import { useDisabledDate } from '@/composables/DisabledDate'
import FilterWilayahSemua from '@/components/filter/FilterWilayahSemua'
import FilterSegment from '@/components/filter/FilterSegment'
import FilterRole from '@/components/filter/FilterRole'
import FilterSales from '@/components/filter/FilterSales'
import FilterCluster from '@/components/filter/FilterCluster'
import FilterSalesman from '@/components/filter/FilterSalesman'
import SuggestCustomerGroup from '@/components/suggest/SuggestCustomerGroup'

import moment from 'moment'

export default defineComponent({
  components: {
    FilterWilayahSemua,
    FilterBrand,
    SelectDistributor,
    FilterSegment,
    FilterRole,
    FilterSales,
    FilterCluster,
    FilterSalesman,
    SuggestCustomerGroup,
    // FilterKabupatenKota,
    // FilterProvince,
  },
  setup(props, { emit }) {
    const { disabledStartDated, disabledEndDated } = useDisabledDate()
    const defWrapCol = reactive({ md: { span: 16, offset: 4 }, sm: { span: 24 } })
    const formState = inject('formState')
    const foto_ktp = toRef(formState.value, 'foto_ktp')
    const foto = toRef(formState.value, 'foto')
    const logo = toRef(formState.value, 'logo')
    const document = toRef(formState.value, 'document')
    const segment = toRef(formState.value, 'type')
    const readOnly = false
    const visible = ref(false)
    const dataWilayah = ref([])
    const dataProvince = ref([])
    const distributors = ref([])
    const roles = ref([55403, 55407, 55414])
    const parents = ref([0, 0, 0, 0])
    const isCustomerCurah = ref(false)
    const codeType = ref('number')

    const fotos = reactive({
      foto_ktp_image: null,
      foto_image: undefined,
      logo_image: undefined,
    })

    const formRef = ref('')

    const selectSegmentValue = () => {
      if (segment.value == '1111') isCustomerCurah.value = true, codeType.value = 'text'
      else isCustomerCurah.value = false, codeType.value = 'number'
    }

    const code = ref({
      id: null,
      brand_id: null,
      vendor_id: null,
      code: null,
      begin_date: null,
      end_date: null,
    })

    const codes = toRef(formState.value, 'codes')

    const users = toRef(formState.value, 'users')

    onBeforeMount(() => {
      if (formState.value.codes === undefined || formState.value.codes === null) {
        formState.value.codes = []
      }

      distributors.value = formState.value.distributors || []

      if (formState.value.salesman === undefined || formState.value.salesman === null) {
        formState.value.salesman = []
      }
      formState.value.salesmans = []

      selectSegmentValue()

      var sales = []
      for (let i = 0; i < roles.value.length; i++) {
        let params = { id: 0, role_id: roles.value[i] }
        if (formState.value.users != undefined || formState.value.users != null) {
          if (formState.value.users[i] != undefined || formState.value.users[i] != null) {
            if (formState.value.users[i].id != undefined || formState.value.users[i].id != null) {
              params.id = formState.value.users[i].id
            }
            if (
              formState.value.users[i].vendor_id != undefined ||
              formState.value.users[i].vendor_id != null
            ) {
              params.vendor_id = formState.value.users[i].vendor_id
            }
            if (
              formState.value.users[i].user_id != undefined ||
              formState.value.users[i].user_id != null
            ) {
              params.user_id = formState.value.users[i].user_id
              parents.value[i + 1] = formState.value.users[i].user_id
            }
            if (
              formState.value.users[i].begin_date != undefined ||
              formState.value.users[i].begin_date != null
            ) {
              params.begin_date = formState.value.users[i].begin_date
            }
            if (
              formState.value.users[i].end_date != undefined ||
              formState.value.users[i].end_date != null
            ) {
              params.end_date = formState.value.users[i].end_date
            }
          }
        }
        sales.push(params)
      }
      formState.value.users = sales
    })

    const generateEmail = () => console.log('genraete')

    const isNew = computed(() => formState.value.state === 'new')

    const urlUpload = apiClient.defaults.baseURL + '/api/upload'
    // filelist upload, foto distributor, foto, foto, document
    const fKtp = ref([])
    const ktp = ref(true)
    const ifFoto = ref(true)
    const ifLogo = ref(true)
    const ifDokumen = ref(true)

    const onFileChange = async (e, type) => {
      var files = e.target.files || e.dataTransfer.files

      const allowed_extensions = new Array('jpg', 'png', 'gif', 'jpeg')
      const isExtOk = fileName => {
        const file_extension = fileName
          .split('.')
          .pop()
          .toLowerCase()
        return allowed_extensions.includes(file_extension)
      }

      if (!files.length) return
      // reset visual of file uploader because of html bug
      const resetVisualUploader = async () => {
        if ('ktp' === type) {
          foto_ktp.value = true
        } else if ('foto' === type) {
          foto.value = true
        } else if ('logo' === type) {
          logo.value = true
        } else if ('document' === type) {
          document.value = true
        }
        await nextTick()

        if ('ktp' === type) {
          foto_ktp.value = null
        } else if ('foto' === type) {
          foto.value = null
        } else if ('logo' === type) {
          logo.value = null
        } else if ('document' === type) {
          document.value = null
        }
      }

      // only 1 file upload allowed, lower than 2mb and some ext of image files
      // but not for document
      if (!isExtOk(files[0].name) && type !== 'document') {
        message.warning(`Hanya Boleh Gambar ${allowed_extensions}`)
        await resetVisualUploader()
        return
      }

      var filesize = (files[0].size / 1024 / 1024).toFixed(4) // MB
      if (filesize > 2.0) {
        message.warning('Ukuran harus di bawah 2MB!', 5.0)
        await resetVisualUploader()
        return
      }

      if ('ktp' === type) {
        foto_ktp.value = files[0]
        imageToBase64(foto_ktp.value, toRefs(fotos).foto_ktp_image)
      } else if ('foto' === type) {
        foto.value = files[0]
        imageToBase64(foto.value, toRefs(fotos).foto_image)
      } else if ('logo' === type) {
        logo.value = files[0]
        imageToBase64(logo.value, toRefs(fotos).logo_image)
      } else if ('document' === type) {
        document.value = files[0]
      }
    }

    const imageToBase64 = (file, to) => {
      var reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        to.value = reader.result
      }
      reader.onerror = function(error) {
        console.log('Error: ', error)
      }
    }

    /// vendor code
    const addCode = () => {
      if (codes.value.filter(v => v.code === null).length > 0) {
        message.warning('Pastikan kode referensi terisi!')
        return
      }
      isAddCode.value = false
      const m = Object.assign({}, code.value)
      // m.id = 1
      codes.value.push(m)
      // resetCode()
    }

    const removeRow = item => {
      const index = codes.value.indexOf(item)
      if (item.id === null) codes.value.splice(index, 1)
      else {
        itemDelete.item = item
        itemDelete.showReason = true
      }
    }

    const isAddCode = ref(true)

    const saveCode = () => {
      isAddCode.value = true
      resetCode()
    }
    const batalCode = () => {
      isAddCode.value = true
    }
    const resetCode = () => {
      code.value = Object.assign(
        {},
        {
          id: null,
          brand_id: null,
          vendor_id: null,
          code: null,
          begin_date: null,
          end_date: null,
        },
      )
    }

    const today = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate(),
      0,
      0,
      0,
    )
    const isEndDateExpired = end_date_temp => {
      return typeof end_date_temp === 'string'
        ? new Date(end_date_temp).getTime() < today.getTime()
        : false
    }
    const itemDelete = reactive({
      showReason: false,
      item: null,
      reason: '',
      error: null,
    })

    const submitReason = () => {
      if (itemDelete.reason === null || itemDelete.reason.trim() === '') {
        itemDelete.error = 'Alasan tidak boleh kosong!'
        return
      } else {
        itemDelete.error = null
      }

      itemDelete.item.audit_trail_note = itemDelete.reason
      itemDelete.item.is_deleted = true
      itemDelete.item.end_date = new moment(today)
      itemDelete.showReason = false
      itemDelete.item = null
      itemDelete.reason = ''
    }

    const onChangeParent = (value, index) => {
      parents.value[index + 1] = value
    }
    const inputValueLat = ref('')
    const inputValueLong = ref('')

    const latRegex = /^-?(?:90(?:\.0+)?|[0-8]?\d(?:\.\d+)?)$/

    const longRegex = /^-?(?:180(?:\.0+)?|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:\.\d+)?)$/

    const isValidLatitude = computed(() => {
      return latRegex.test(formState.value.lat)
    })
    const isValidLongitude = computed(() => {
      return longRegex.test(formState.value.lng)
    })

    return {
      // dateFormat,,
      longRegex,
      latRegex,
      inputValueLat,
      inputValueLong,
      defWrapCol,
      formState,
      generateEmail,
      dataProvince,
      distributors,
      dataWilayah,
      isNew,
      visible,
      addCode,
      code,
      codes,
      removeRow,
      fKtp,
      urlUpload,
      onFileChange,
      onChangeParent,
      ktp,
      ifFoto,
      ifLogo,
      ifDokumen,
      foto_ktp,
      foto,
      logo,
      document,
      segment,
      imageToBase64,
      isAddCode,
      saveCode,
      batalCode,
      formRef,
      rules,
      ...toRefs(fotos),
      disabledEndDated,
      disabledStartDated,
      itemDelete,
      submitReason,
      isEndDateExpired,
      users,
      roles,
      parents,
      isValidLatitude,
      isValidLongitude,
      isCustomerCurah,
      selectSegmentValue,
      codeType,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '@/css/form.scss';

.danger-input {
  border: 1px solid red;
}

.position-danger {
  position: relative;
  top: -10px;
}
</style>
